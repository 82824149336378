import { memo, useMemo } from 'react';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import Image from 'next/image';

import styles from '@/styles/Components/Home/Home.module.scss';

const SectionPage = ({ blogsData }: { blogsData: any }) => {
  const children = useMemo(() => {
    return (
      <Row>
        {blogsData?.map((item: any, index: number) => (
          <Col key={index} md="4">
            <div className={styles.BlogBox}>
              <Link href={`/blogs/${item.slug}`}>
                <div className={styles.HomeBlogImg}>
                  <Image
                    quality={75}
                    src={item?.image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = '/assets/img/no-found.webp';
                    }}
                    width={300}
                    height={300}
                    alt={item?.title ?? 'Call Girls and Escort Services'}
                  />
                </div>
                <div className={styles.HomeBlogCont}>
                  <h4>{item.title}</h4>
                  <p className={styles.BlogDateTime}>{item.event_date}</p>
                </div>
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogsData]);
  return (
    <section className={styles.HomeBlogSec}>
      <Container>
        <div className="title-cls">
          <h2>Call Girls Blogs</h2>
        </div>
        {children}
        <div className={styles.ViewAll}>
          <Link href="/blogs" type="button" className={'btn btn_primary'}>
            View All
          </Link>
        </div>
      </Container>
    </section>
  );
};
export default memo(SectionPage);
